export const termsSections = [
//     {
//         title: "1. Introduction - Who We Are and What We Do",
//         content: `We are the brand of JHUMKA GAMING SOFTWARE PRIVATE LIMITED , offering Our Platform to You and an opportunity for You to participate in Contests spanning across a broad range of officially sanctioned live sporting events (“Services”). An illustrative list of such sporting events is mentioned below as maybe modified from time to time (“Sports”):
  
//     i. Cricket
//     ii. Football
//     iii. Basketball
//     iv. Baseball
//     v. Hockey
//     vi. Handball
//     vii. Volleyball
//     viii. Kabaddi
  
// Any person using, accessing and/or participating in any fantasy sports-related free-to-play online gaming contests (“Practice Contest”) and/or pay-to-play online gaming contests (“Paid Contest”) on Our Platform is a user (“User”). All references to “You/Your” relate to the User. All references to “We/Us/Our” relate to “Funzone360.com” which denotes a collective reference to the Funzone360.com mobile application and the Funzone360.com website (hereinafter collectively referred to as “Platform”). Practice Contest and Paid Contest are hereinafter collectively referred to as “Contests”.`,
//     },
    {
        title: "2. Acceptance of Our Terms and Conditions",
        content: `Your continued usage of Our Platform constitutes Your acceptance of the terms and conditions, including any additional terms that We may make available to You (“Terms”) and are contractually binding on You.
  
You accept that in the event of any variance between these Terms and any additional terms relating to the usage of Our Platform or any Contest, the additional terms will prevail.
  
By accepting these Terms, You agree and consent to receive communications from Us and/or Our partners, licensors or associates for any purpose through the following modes:
    i. Announcements;
    ii. Administrative messages/direct messages;
    iii. Advertisements;
    iv. Direct notification to your account; and
    v. By any other means that We may consider fit for this purpose.
  
You agree that You shall mark Us as a safe sender on all Your platforms where You receive any such communications from Us (including via email and SMS) to ensure such communications are not transferred to the spam/junk folder.`,
    },
    {
        title: "3. User Account",
        content: `i. You are required to register on Our Platform to create an account to access Our Services (“Account”). At the time of creating Your Account and/or at any time during the utilisation of Our Services, You will be required to provide any and/or all of the following information and/or documents:
      1. Your full name;
      2. Your team name;
      3. Your mobile number;
      4. Your full address and state of residence;
      5. Your gender; and
      6. Your date of birth.
      7. Aadhar number
      8. Any other valid identification
      9. Bank Account information
      10. UPI ID
      11. Permanent Account Number (PAN)
We reserve the right to conduct KYC as may be required from time to time in the manner prescribed under such law.`,
    },
    {
        title: "4. Eligibility",
        content: `i. To participate in any Paid Contest, you must meet the following eligibility criteria:
       1. You must be above the age of 18 years.
       2. You must be residing in India.
       3. You must have a valid Indian mobile number to create an Account.
ii. Please note that Paid Contests are not permitted in the following states (“Restricted States”):
       1. Andhra Pradesh;
       2. Assam;
       3. Nagaland;
       4. Sikkim; and
       5. Telangana
iii. You agree that You are not from any of these Restricted States and You shall refrain from making any false disclosures, declarations and/or representations to Us to circumvent the applicable laws and/or regulations of the territory of India (“Applicable Law”) of the Restricted States to participate in any Paid Contests.`,
    },
    {
        title: "5. Contest Rules and Format",
        content: `1. CONTEST RULES
          You agree and acknowledge that:
          1. To participate in a Contest(s), You are required to create a fantasy team (“Team”), by selecting real-life players who are participating in the specific Sports.
          2. You must finalise Your Team for the relevant Contest before the start time and/or any other adjusted time (as specified below) of the Sports (“Contest Deadline”). We may notify any extensions to the Contest Deadline in advance.
          3. Each Contest will require a minimum number of Users to participate in the Contest for it to become operational (“Pre-specified Number of Users”)
          4. In the case of a multiple-entry contest, a User may create multiple Teams to participate in such a Contest (“Multiple Entry Contest”). We reserve Our right in Our sole discretion to restrict the maximum number of Teams You may submit for each Contest format.
          5. You may participate in the Paid Contests by paying a pre-designated amount as specified on the relevant Contest page (“Pre-Designated Amount”).
          6. For each Contest, We will create a prize pool consisting of contributions from all participating Users (“Prize Money Pool”).
          7. For clarity, we have no right or interest in this Prize Money Pool.
          8. We reserve a right to deduct a fee from the Pre-Designated Amount in lieu of the Service being provided by Us (“Platform Fees”) and the balance amount shall constitute Your contribution towards the Prize Money Pool.
          9. From time to time, we may conduct/organize promotions/offers on the Platform. Your participation in such promotions/ offers shall be in accordance with the terms and conditions applicable to the Promotion.
          10. You can participate in up to 2000 Contests per match in cricket and up to 500 Contests per match in any other sports.
          11. You can only create a maximum of 20 (twenty) Teams for any Contest You are participating in.
          12. Our starting line-up feature in relation to the Contests is only to guide and assist the User in selecting their Team(s). In indicating a player’s inclusion in a starting line-up, We rely on information/data received from third-party data feed service providers (“Data Feed Providers”) and/or publicly available information.
          13. Prior to creating your Team, We strongly recommend You conduct Your own independent research of player statistics, pitch conditions, weather conditions, player performances, track record of competing teams, winning record of captains and vice captains.
          14. Flexible and Non-Flexible Contest
              (a) A Contest will be operational even if a minimum of (2)two Users join such a Contest (“Flexible Contest”)
              (b) A Contest will be operational only if the number of Users participating in such Contest is equal to the limit set by us in case of a Public Contest and by You in case of a Private Contest (“Non-Flexible Contest”)
              (c) The Prize Money Pool shall be directly proportional to the number of Users participating in a Flexible Contest. For clarity, the Prize Money Pool specified on the Contest page will decrease if the number of Users joining the Contest is less than the limit set by You for a Private Contest and by Us for a Public Contest.
          15. In participating in Contests, You agree to strictly comply with the Terms, including additional rules published by Us, such as:
              (a) “Fantasy Rules” 
              (b) any other rules and regulations (including without limitation in relation to payments made to participate in any Contest)
          
2. CONTEST FORMATS
          1. Public Contest
          A Public Contest is a Contest where You can participate with other Users without any restrictions.
          Features of Public Contests:
              a. Public Contest can either be a Flexible Contest or a Non-Flexible Contest.
              b. We reserve the right to cancel the Public Contest if the number of participating Users is less than the Pre Specified Number of Users. If cancelled We will return Your Pre Designated Amount without any deduction.
              c. If a Contest is labelled as a "Guaranteed Contest" it will become operational when the Pre-Specified Number of Users join the Contest. The Prize Money Pool for each Guaranteed Contest is predetermined. We will cover the shortfall if there are not enough Users in a Guaranteed Contest to meet the guaranteed Prize Money Pool.
              d. We will declare the winners as per the Contest page, regardless of the number of Users joining the Contest
          
          2. Private Contest
          A Private Contest is where You can create a Contest and invite other Users (“Invited Users”) to join your Contest.
          Features of Private Contest:
              a. To create a Private Contest, You need to ensure
                  i. You provide a name to the Private Contest;
                  ii. You set a limit on the number of Users joining the Private Contest, which can range from 2-20,000 users;
                  iii. You will have the right to select whether Your Contest will be a Flexible Contest or a Non - Flexible Contest.
                  iv. Pay the Pre-Designated Amount to join the Private Contest.
              b. After You create a Private Contest, We will provide a unique identification code for the Private Contest that You can share with other Users for participating in the Private Contest (“Contest Code”).
              c. You will not be able to make any changes to the terms of the Private Contest once created
              d. Invited User(s) must enter the Contest Code and pay the Pre-Designated Amount to join the Private Contest.
              e. We will refund the Pre-Designated Amount if one hour before the Contest Deadline, the users participating in the Private Contest are less than
                  i. either the Pre-Specified Number of Users as applicable for a Flexible Contest; or
                  ii. the limit set by You for a Non-Flexible Contest.
              f. We will not be liable to the Invited User(s) for their inability to join the Private Contest for any reason not attributable to Us`
    },
    {
        title: "6. Winning",
        content: `i. WINNER DECLARATION
          1. Teams are awarded points based on the performance of Your Team at the end of the Contest. The User’s Team with the highest score(s) in the Contest will be declared as the winner (“Winners”). In certain pre-specified Contests having more than one Winner, the distribution of prizes to such Winners will be in increasing order of their Team’s aggregate score at the end of the relevant Sports relating to such Contest.
          2. The Winner(s) will be selected based on the highest aggregate scoring User Team in a particular Contest (where the duration of the Sports can range from 1 day to 5 days).
          3. The number of eligible winners and prizes for the Contest(s) will be announced on the Contest page before the Contest begins.
          
          ii. GENERAL RULES FOR DISTRIBUTION OF WINNINGS
          1. The User accumulating the highest points at the end of a match shall be eligible to win a pre-designated winning disbursed out of the Prize Money Pool as stated on the relevant Contest page.
          2. If there is a tie, the winnings will be distributed evenly among the declared winners.
          3. We reserve the right to vary or modify the winnings at Our discretion before the Contest Deadline.
          4. You agree that any winnings in the nature of prizes or rewards awarded by Us shall be governed by the terms and conditions of such specific promotional events.
          5. We reserve the right to disqualify any Winner and prevent them from withdrawing their winnings or receiving any prize if:
             a. any of the document or information submitted by You are incorrect, misleading, false, fabricated, incomplete or illegible; or
             b. You created teams on behalf of other Users.
             c. You do not abide by these Terms.
             d. You have falsely declared your State and entered the contest from prohibited states as per clause IV above.
          
          iii. TABULATION AND SCORING
          We use Data Feed Providers and/or the official website of the Sports Event organiser to obtain scores and relevant information required for calculating the fantasy points.
          If there is an error in calculating the fantasy points that are brought to Our attention due to inaccuracies or incompleteness of the information provided by the Data Feed Provider, We shall do Our best to rectify said errors before distributing the prizes.
          
          iv. VERIFICATION OF WINNERS
          1. We or our third-party service providers working on behalf of us may contact the Winners on the email address provided at the time of Account creation. At this stage, Winner will be explained the details of the verification process and the documents required for collecting the prize.
          2. If You have been declared as a Winner on Our Platform but have not received Your Winnings, You may contact Our Helpdesk within forty-eight (48) hours of the Winner declaration to help us resolve your concern at the earliest.
          3. You agree and acknowledge that Our decision with respect to awarding the Winnings as per the Terms will be final and binding on You.`,
    },
    {
        title: "7. Payment Terms",
        content: `i. PAYMENT ACCOUNTS
        By accessing and using Our Platform, You are provided with the following categories of accounts for processing and reconciling Your payments:
        1. Unutilized Account - This account holds any amount remitted by You through a designated payment gateway for availing Our Services; and
        2. Winning Account - This account holds your winnings in any Paid Contests.
        
        ii. DISCOUNT OFFERED
        1. In order to conduct promotional activities and provide incentives, We may issue discounts to You which may be utilised for joining any paid Public Contest(s) (“Discount(s)”). You agree to the following:
           a. You shall not be permitted to transfer or withdraw any amount of Discounts offered to any other account including Your bank account.
           b. The utilisation of any Discount shall be subject to certain terms which will be notified to You at the time of issuance of any Discount.
           c. Discount shall be issued at our sole discretion and will not be demanded as a matter of right.
           d. Discount will be applied towards the Pre-designated Amount for joining any Contest.
           e. Upon termination of Your Account, all Discounts shall be forfeited and You shall not have any right or interest on such Discount.
           f. Any Discount issued to you may take up to 24 hours to reflect in their respective Discount account as defined below. You agree not to hold Us responsible or liable for any delay, including any loss of opportunity to join any Contest(s) due to delay in crediting the Discount.
           g. In the event of any conflict between these Terms and the terms specific to the Discount, if any, the Discount terms shall prevail.
        2. We offer the following Discounts:
           a. Discount Point
              We may award discount points at the time of a successful deposit in your Unutilised Account (“Discount Point”). Your utilisation of the Discount Points shall be subject to the following terms and conditions:
              a. Discount Points shall be valid for a period of three (03) months from the date of issuance.
              b. Discount Points shall be credited to your Discount Point account (“Discount Point Account”).
              c. While participating in any Paid Contest, a certain percentage of available Discount Points in Your Discount Point Account shall be applied to the Pre Designated Amount.
              d. Deduction of Discount Points from the Pre-Designated Amount is in addition to any other Discounts available to You.
              e. You agree that in the event of any request for refund of the amount deposited in the Unutilised Account, any Discount Points awarded to you in connection with such amount deposited shall stand forfeited and you will not be able to utilise such Discount Points.
              f. If a match is abandoned or cancelled, the applicable Discount Points deducted from the Pre Designated Amount shall be returned to Your Discount Point Account.
           b. Discount Bonus
              We may at our discretion as part of our Promotion issue a Discount bonus to You in accordance with the terms and conditions applicable to such Promotions (“Discount Bonus”). In addition to the terms relating to the Promotion, You agree to the following:
              a. Discount Bonus shall be credited to your Discount Bonus account upon fulfilling the applicable conditions under the respective Promotions (“Discount Bonus Account”).
              b. Discount Bonus will be automatically deducted from the Pre- Designated Amount payable by You.
              c. Discount Bonuses shall be valid for a period of 14 days from the date of the credit.
           c. Discount Coupons
              We may grant Discount coupons at Our sole discretion and each Discount coupon shall be governed by the specific terms and conditions as notified by Us at the time of grant of such Discount coupons. You acknowledge that Discount coupons shall have their own validity and specific terms and conditions available on the Platform. In case of any conflict between these Terms and the terms specific to the Discount coupon, the terms specific to the Discount coupon shall prevail.
        
        iii. PRE-DESIGNATED AMOUNT PAYMENT
        1. When You join any Contest, subject to the deduction of Discount Bonus and Discount Points (if any), the Pre-Designated Amount gets debited first from the Unutilised Account, and then from Your Winnings Account. For the Unutilized Account, debits will be made in order of the date when the funds were first credited.
        2. If You do not have enough balance in either of your Accounts to participate in any Paid Contest, You will need to remit the Pre-Designated Amount required to join the Contest(s). You agree that any amount deposited shall be subject to deduction of applicable GST in accordance with these Terms.
        
        iv. WITHDRAWALS PROCESSING
        1. You will be prompted to provide certain documents for processing your payments and verification purposes at the time of your first withdrawal.
        2. The name mentioned on the identification document as provided by You at the time of withdrawal should correspond with the name provided by You at the time of Account creation.
        3. We will process your withdrawal after verifying your bank account details and/or UPI ID (as applicable). Upon verification, we will debit the amount from your Winning Account and transfer it online to your bank account at the earliest.
        4. We may charge any processing fee for the online transfer of funds from your Winning Account to Your bank account.
        5. We depend on banks and third parties to process transactions on Our Platform. Thus, we may take up to 24 hours to process any payments to Your bank account. You agree not to hold Us responsible or liable for any delay in processing any payments.
        6. A transaction, once confirmed, is final, and no cancellation is permissible.
        
        v. TAXES PAYABLE
        1. From 01 October 2023, 28% GST is applicable on the amount deposited by You in Your Unutilised Account. You can view the GST paid on each deposit made by You in the Unutilised Account on the ‘My Transaction’ page on the Platform. The GST value listed on the invoice shall be the final GST amount charged to You.
        2. All winnings shall be subject to deduction of tax (“TDS”) as per the Income Tax Act 1961. With effect from 1st April 2023, TDS of 30% shall be deducted from Net Winnings (“NW”) on each withdrawal request placed by You.
        3. For the purpose of calculating NW:
           a. Calculation of NW for the first withdrawal during the Financial Year (“FY”):
              NW = A - (B + C)
              A = Aggregate amount withdrawn from the Winning Account;
              B = Aggregate amount of non-taxable deposit made in the Unutilised Account till the time of withdrawal;
              C = balance amount in the Unutilised Account and the Winning Account at the beginning of the FY (“Opening Balance”);
              NW shall be Zero if the sum of B & C is equal to or greater than A.
           b. Calculation of NW during the FY in each subsequent withdrawal from the Winning Account:
              NW = A - (B + C + E)
              A = Aggregate amount withdrawn from the Winning Account till the time of such withdrawal including the amount of such subsequent withdrawal;
              B = Aggregate amount of non-taxable deposit made in the Unutilised Account till the time of such subsequent withdrawal;
              C = balance amount in the Unutilised Account and the Winning Account at the beginning of the FY (“Opening Balance”);
              E = NW comprised in earlier withdrawal or withdrawals during the FY until this subsequent withdrawal if tax has been deduced on winnings comprised in such withdrawal or withdrawals.
              NW shall be Zero if the sum of B, C & E is equal to or greater than A.
           c. Calculation of NW at the end of the FY:
              NW = (A + D) - (B + C + E)
              A = Aggregate amount withdrawn from the Winning Account during the FY;
              B = Aggregate amount of non-taxable deposit made in the Unutilised Account during the FY;
              C = balance amount in the Unutilised Account and the Winning Account at the beginning of the FY (“Opening Balance”);
              D = balance amount in the Unutilised Account and Winning Account at the end of the FY (“Closing Balance”);
              E = Net Winnings comprised in earlier withdrawal or withdrawals during the FY until this subsequent withdrawal if tax has been deduced on winnings comprised in such withdrawal or withdrawals.
              NW shall be Zero if the sum of B, C & E is equal to or greater than the sum of A & D.
        4. In the event, You do not withdraw any of Your winnings during a financial year and You have Net Winning balance as of 31 March of each year, then TDS of 30% shall be deducted on such Net Winnings balance from Your Winning Account as on 31 March as calculated above.
        5. In case of any revisions by the Government of India to the aforementioned rate or the definition of Net Winnings in the future, TDS will be deducted by Us in accordance with the then-current prescribed TDS rate and the revised definition of Net Winnings. Winners will be provided TDS certificates in respect of such tax deductions. The Winners shall be responsible for payment of any other applicable taxes, including but not limited to, income tax, gift tax, etc. in respect of the Net Winnings. We shall not in any manner be responsible for users' individual tax matters.
        
        
        vi. USER FUNDS
        1. You hereby authorise Us to appoint an independent third-party/ trustee/ escrow agent to act on your behalf in a fiduciary capacity (“Third Party”) with respect to holding any amount belonging to You and undertaking actions, consents, approvals and any other requisite instructions necessary for such amount.
        2. You acknowledge that:
           a. We do not hold any right, title and/or interest in the amount deposited by You.
           b. The amount deposited by You will be used only for participation in the Paid Contest.
           c. Any amount deposited by You shall not be forfeited by Us except in case where We receive an instruction from any government agency or competent authority.
        3. You further authorize Us to instruct the Third Party to open a non-interest earning bank account(s) which will be used for the following payouts:
           a. Withdrawal of Your winnings;
           b. Payment of Platform Fee;
           c. Payment of taxes as applicable towards TDS on Net Winnings; and
           d. Government (towards GST payable).`
    },
    {
        title: "8. Your Conduct",
        content: `In using and accessing Our Platform, You agree to observe the following code of conduct:
      1. ACCOUNT INFORMATION
         a. You are permitted to create only one Account on Our Platform. When creating Your Account with Us (or updating Your Account information), You agree that You will provide true, accurate and updated information and documentation. You will not provide any false and/or incorrect information and documentation nor impersonate or attempt to impersonate and/or otherwise assume the identity of another person without due authority. You will not commit any such fraudulent act which amounts to forgery and/or involves the fabrication of documentation.
         b. You agree and acknowledge that the address-related details provided by You shall be treated as Your “Address on record” for the purposes of the Goods and Service Tax Act.
         c. You agree that You shall promptly update your Account related details on Our Platform in the event of any change in such details and/or write to Our helpdesk in the event of any; a) change in Your Account related details and/or; b) any unauthorised use of Your Account to enable us to take remedial action.
      
      2. MAINTAIN CONFIDENTIALITY
         a. You will maintain the confidentiality of all information relating to Your Account, and You will not share Your One Time Password (“OTP”) with any other person;
         b. You will not engage in any fraudulent conduct in logging into another user’s account by asking for their account-related information or taking their OTP.
      
      3. COMPLIANCE WITH APPLICABLE LAW
         a. You agree to comply with Applicable Laws.
         b. You agree that You shall not commit any illegal act that disrupts Our systems.
      
      4. VIOLATION OF PLATFORM TERMS
         a. You shall not engage in any gaming rule violation.
         b. You shall not violate any of the Terms in using and accessing Our Platform and Services;
         c. You shall not misuse any Discount and/or any other offers or promotions given by Us.
         d. You agree that you shall use the Platform and Our Services solely in Your personal capacity, and You will not engage in malpractices or collude with other Users in deriving any benefit and/or running any business in connection with the use of Our Platform and/or Services.
         e. You shall not be engaged in any form of insider trading, i.e. illegally sharing and seeking non-public information, knowledge of participating teams in any given contests/match, strategies, organizing boards, leagues etc. which may give You an unfair competitive advantage. (“Insider Trading”)
         f. You shall not tamper, modify, or otherwise deal with our data, content, software, technology and/or Intellectual Property by any means.
      
      5. RESPONSIBLE PLAY
         We're committed to fostering responsible gaming for all our users. You agree to participate in the Contests responsibly by making informed decisions and playing within Your means. You are encouraged to seek help if needed.
      
      6. ADDITIONAL FEATURES
         We offer Our Users additional features on our platform
         a. Chat Feature: is a tool that allows You to communicate with each other in real-time on the Platform either in a public or private setting (“Chat Feature”). You agree to observe the following code of conduct:
            i. You shall not engage in illegal, obscene, abusive, offensive, racially insensitive, communal, objectionable, defamatory, or otherwise inappropriate and immoral conduct
            ii. You shall not harass, bully, stalk, threaten, or otherwise violate any rights of other Users;
            iii. You shall be responsible for all content published, contributed, and made available by You on Our Platform. This includes any content You share with Us (“User Content/Your Content”).
            iv. Your Content shall not be immoral, profane, defamatory, or offensive;
            v. You shall not use Our Platform for advertising, offering or selling any goods or services for commercial gain except with Our prior written consent;
            vi. You shall not restrict or inhibit any other User from using Our Platform;
            vii. You shall not publish any content on Our Platform which is patently false, with the intent to mislead or harass any person or third party, whether for financial gain or to cause any injury to any person or otherwise;
            viii. You shall not collude with any other User(s) or engage in syndicate play;
            ix. You agree that such behaviour qualifies as User misconduct.
            x. You shall not spam other User(s) by sending any unsolicited communications;
            xi. You will not collect, store, or obtain any information about other Users without their prior written consent;
            xii. You shall not engage in any action that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nations.
      `
    },
    {
        title: "9. Our Rights and Obligations",
        content: `i. YOUR CONTENT
         - We reserve our rights to remove (without notice) any offending/illegal/objectionable/ infringing content posted by You and take such necessary action as We may consider appropriate concerning Your Content;
      
    ii. CONTEST
         1. We may choose to:
            a. Revise the roster of players available for selection in relation to any Contest/Sports offered on Our Platform based on the official information We may receive from the sports organiser or any third-party sources.
            b. Withdraw and/or cancel any Contest without prior notice to You should the carrying on of such Contest be prohibited and/or require any governmental, statutory or regulatory compliances or approvals;
            c. Adjust the Contest Deadline or Abandon any Contest.
         
         - We reserve the right to abandon a Contest upon the occurrence of circumstances beyond Our reasonable control, including circumstances specified below:
            1. If the Sports (other than Cricket matches) is rescheduled to take place before the Contest Deadline and whose start time is more than 10 mins of the Contest Deadline.
            2. If the Contest Deadline for cricket matches is extended by more than 10 minutes, or 3(three) overs have been bowled (whichever is less), before the official start time of the cricket event;
            3. If the Sports is rescheduled to take place after the Contest Deadline for any reason, including interruptions and delays caused due to weather, toss delays, non-availability and non-appearance of Teams, technical glitches etc and the start time of the relevant Sports cannot be verified by Us through publicly available sources.
         
         - We reserve Our Right to adjust the Contest Deadline upon the occurrence of circumstances beyond Our reasonable control, including the following circumstances:
            1. For Sports other than cricket - If the Sport is rescheduled to take place before the Contest Deadline, We reserve Our Right to adjust the Contest Deadline to a maximum of 10 minutes post actual start time of the relevant Sports;
            2. Suppose the cricket event is rescheduled to take place before the Contest Deadline. In that case, We reserve Our Right to adjust the Contest Deadline by a maximum of 10 minutes, or 3(three) overs bowled (whichever is less), before the official start time of the cricket event;
            3. If the cricket event is rescheduled to take place before the Contest Deadline and the official start time of the cricket event cannot be determined and verified by Us through publicly available sources, We may adjust the Contest Deadline to such a time by which a maximum of 3 overs in a given match are bowled; or
            4. If the Sport is rescheduled to take place after the Contest Deadline for any reason including, interruptions and delays caused due to weather, toss delays, technical glitches etc., based on reliable and/or publicly available sources, We may extend the Contest Deadline to a time which is synonymous with the actual start time of the Sport.
         
    iii. OUR SERVICES
         1. We may choose to
            a. Change, discontinue or suspend all or any part of Our Service;
            b. Suspend or terminate Your Account as directed by an appropriate government agency and/or competent authority.
         
    iv. BREACH OF TERMS
         - In case of breach of these Terms or if We reasonably believe that You have breached these Terms, We may choose to:
            1. Restrict, suspend, deactivate or permanently delete Your account with Us (including all related information that You might have provided to Us)
            2. Disqualify You from participating in a Contest;
            3. Refrain from awarding any prizes to You or forfeit Your winnings
            4. Initiate appropriate civil/criminal proceedings as We may consider appropriate in addition to forfeiture and/or recovery of prize money.`
    },
    {
        title: "10. Intellectual Property Rights",
        content: `i. You agree that We (including Our partners, licensors and/or associates) own all intellectual property rights in and to the software underlying the creation of Our Platform, including all material and content published by Us on Our Platform, which includes (but is not limited) to the following:
         1. Our Contests
         2. Our software/technology
         3. Our advertisements
         4. Our written/published content
         5. Our images, graphics, photographs, illustrations
         6. Our marks, logos, trade name
         7. Audio and visual clippings used and published by Us
         8. Flash animation (“Our Intellectual Property Rights/Our Content”)
         
      ii. You agree that in accessing Our Intellectual Property Rights, You shall not do the following without Our prior written consent:
         1. Modify, distribute, publish, transmit, reproduce, or otherwise deal with Our Intellectual Property Rights; or
         2. Create derivative works of Our Intellectual Property Rights or in any way exploit Our Intellectual Property Rights.
         
      iii. You agree that:
         1. You own all the intellectual property rights in Your content;
         2. Your content will not result in any infringement claims from any third party; and
         3. We are not liable for any of Your content shared and/or contributed by You on Our Platform.
         
      iv. You grant us a worldwide, non-exclusive, royalty-free, sublicensable, irrevocable, and transferable license to use Your content for the following:
         1. Use, reproduce, distribute, publish, and transmit Your content; and
         2. Otherwise, create any derivative works of the User Content for the following purposes:
            i. Display of User Content on Our Platform;
            ii. Distribution and/or storage of User Content (either electronically or via other media) for any purpose; and
            iii. Storing Users' Content in a remote database accessible by Users, for a charge.`
    },
    {
        title: "11.	PRIVACY POLICY",
        content: "All Your personal information collected (including financial data) is subject to Our Privacy Policy which is available at Privacy Policy. You are encouraged to review this Privacy Policy to understand the data we collect and the purpose for which such data is collected"
    },
    {
        title: "12. Grievance Redressal Mechanism",
        content: `In case you have any complaints or grievance pertaining to:
i. any User Content that You believe violates these Terms including any infringement of Intellectual Property Rights, or
ii. Your access to the Platform,

Please refer Contact Us page
         
On receiving such a complaint, grievance, or notice, the grievance officer may reach out to You to seek further clarification, or to verify Your complaint.

The Grievance Officer identified above is pursuant to the provisions of Applicable Laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws. The Company reserves the right to replace the Grievance Redressal Officer at its discretion through the publication of the name and title of such replacement on the website, which replacement shall come into effect immediately upon publication.

You agree that regardless of any statute or law to the contrary, any complaints or grievances arising out of or related to the use of Our Platform or these Terms should be filed within thirty (30) days of such claim to enable us to resolve Your complaint at the earliest.`
    },
    {
        title: "13. Legality",
        content: `Fantasy Sports offered by Us on our platform is a game of skill and a legally and constitutionally protected business not amounting to betting, gambling and wagering held by the Supreme Court of India and different High Courts of India in multiple judgments.
         
These Contests require You to use Your knowledge, judgement and expertise to primarily determine Your success in such Contests rather than relying on chance.`
    },
    {
        title: "14. Publicity",
        content: `By accepting a prize, You grant Us and Our affiliates an irrevocable, royalty-free, worldwide, perpetual license to use Your name, likeness, image, voice and comments/testimonials for any purpose, including but not limited to advertising and promotional purposes in any media.
         
We may organize promotional activities for which We may require Your presence. We will communicate the details of these activities to You. These promotional activities may include press events, internal meetings and ceremonies/functions.`
    },
    {
        title: "15. Limitation of Liability and Indemnification",
        content: `i. By accessing Our Platform, You hereby agree to indemnify Us and/or any of Our directors, employees, partners, affiliates, associates, and licensors (“Representatives”) against all liability, cost, loss, claims, or expense arising out of Your access to Our Platform, arising due to any negligence on Your part and/or any illegal act on Our Platform and/or non-compliance with these Terms.
         
ii. Neither We nor Our partners, affiliates, licensors, or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of Your use of or inability to use Our Services, even if We have been advised of the possibility of such damages.

iii. You, with this, waive each and every right You may have against Us and/or Our Representatives for any injuries, accidents, or other events that may occur as a result of You accessing Our Platform, participating in Contests, or receiving Contest prizes. This waiver includes any known or unknown, anticipated or unanticipated events.`
    },
    {
        title: "16. Governing Law and Dispute Resolution",
        content: `i. The courts of Mumbai shall have exclusive jurisdiction to determine any and all disputes related to Our Services (including the Contest(s)) (“Dispute”) and grant interim relief in any Dispute referred to arbitration as given below. Any Dispute will be governed by the laws of the Republic of India.
         
ii. If there is a legal Dispute, the party raising the Dispute must send a written notification (“Notification”) to the other party. The parties will then try to resolve the Dispute through amicable discussions. Suppose the dispute remains unresolved within fifteen (15) days of receiving the Notification, in that case, the dispute will be settled through arbitration. We will appoint a sole arbitrator to conduct the arbitration proceedings in English as per the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time. The seat and venue of arbitration shall be Mumbai.

iii. The arbitration award will be final and binding on the parties. Each party will pay for the costs of arbitration and equally share the arbitrator's fees unless the arbitral tribunal decides otherwise. The arbitrator can pass interim orders and awards, including orders for specific performance, and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.

iv. Nothing contained in these Terms shall restrict Us from seeking and obtaining interim or permanent equitable or injunctive relief or any other relief available to safeguard Our interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on Our part to pursue any remedy for monetary damages through the arbitration described herein.`
    },
    {
        title: "17. Other Terms",
        content: `i. These Terms govern Your use of Our Platform and Services and supersede any prior agreements that You may have with Us.
         
ii. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.

iii. In the event any of the provisions of these Terms are held to be invalid, illegal, or unenforceable by any competent authority for any reason, the remaining portions of these Terms, if capable of substantial performance, will remain in full force and effect.

iv. Our employees are not eligible to participate in any paid Public Contest, which is a Public Contest.

v. You acknowledge that You may be subject to additional terms that govern your transactions on Our Platform. You agree that if there is any conflict between these terms and the additional terms, the additional terms will prevail.

vi. We may assign our rights and liabilities in relation to the Services offered by Us to any entity as we may deem fit in the course of our business activity.`
    },
    {
        title: "18. Disclaimers",
        content: `i. We will not be liable for any delay or failure to render the Services resulting from any Force Majeure Event.
         
ii. You may access Our Platform at Your own risk. We are not responsible or liable for any loss or damage (including injuries, death, or loss of property) that You or anyone else may suffer while accessing Our Platform.

iii. You agree that Our Platform may contain third-party advertisements and links to websites/services owned and operated by Our affiliates and/or third parties (“Third Party Content”). We do not endorse the Third Party Content or services of any third parties included on Our platform. We cannot guarantee the accuracy, reliability, or quality of such Third Party Content. Your reliance on Third Party Content is at Your own risk. Any claims that You have regarding Third-Party Content should be directed to the third party in question.

iv. We will not be held responsible for any issues with the storage, deletion, delivery, or accessibility of information or materials to the extent allowed by law.

v. We do not provide any warranty for Our Content, which is provided on an "as is, as available basis”.

vi. We are not responsible for any errors or inaccuracies in the team starting line-up data provided on Our Platform, as it is obtained from external sources or publicly available information.

vii. We are not responsible for any inaccuracies in calculating fantasy points or the selection of winners due to inaccurate or incomplete information received from any third-party service provider.

viii. We make no representations or warranties regarding the quality, suitability, or merchantability of any prizes and are not liable with respect to the same.

ix. We will not be liable for your inability to access Our Platform for any reason beyond our control such as network outage, telecommunication failure, internet suspension, virus, malware etc.

x. We are not responsible for any errors in communication, or omissions in communication, including any errors made by You, which may prevent the results from being communicated to You.

xi. We are not liable for any consequences arising from You sharing any confidential information including but not limited to OTP with any other person.`
    }






];

export const privacyPolicy = [
    {
        content: `
All capitalized terms not defined herein shall have the meaning ascribed to them in the Terms.

We respect the privacy of its User(s) and are committed to protecting it in all respects. With a view to offer an enriching and holistic internet experience to its Users, We offer a vast repository of Services on Our Platform. Kindly take time to read the “About Us” section to know more. Most of the Services are offered for free but you need registration to participate in the Contests. The information about the User(s) is collected by Us as (i) information supplied by User(s) and (ii) information automatically tracked during User(s)’ navigation on the Platform.

Before you submit any information on the Platform, please read this Privacy Policy for an explanation of how We will treat Your information. By using any part of the Platform, You consent to the collection, use, disclosure and transfer of Your information for the purposes outlined in this Privacy Policy and to the collection, processing and maintenance of this information. Your use of any part of the Platform indicates Your acceptance of this Privacy Policy and of the collection, use and disclosure of Your information in accordance with this Privacy Policy.

While You have the option not to provide Us with certain personal information, withdraw Your consent to collect certain information, request temporary suspension of collection of personal information or request deletion of personal information collected, kindly note that in such an event You may not be able to take full advantage of the entire scope of features and services offered to You and We reserve the right not to provide You with Our services.

In the ever-evolving medium of the internet, We reserve our right to periodically review and change Our Privacy Policy to incorporate such future changes as may be considered appropriate and under applicable laws. We will notify You of the change. Any changes to Our Privacy Policy will be posted on this page, so You are always aware of what information We collect, how We use it, how We store it and under what circumstances We disclose it.`,
    },
    {
        title: "Purpose and Usage:",
        content: `To avail certain features on the Platform, Users may be required to provide the following information for the registration process, namely:
        1. Username
        2. Email address
        3. Date of birth
        4. State
        5. Government ID such as Aadhaar card, driving license, or voter ID.
        
Additionally, in the course of providing You with access to the Platform and the Services offered by Us, You agree to give Us permission to capture and record Your device-related information, operating system information, network information, location information, etc. You may choose to allow Us to gather information related to the apps installed on Your device to enhance Our Services and Your experience on the Platform. You may also be required to furnish additional information, including Your Permanent Account Number.
        
In certain instances, We may also collect sensitive personal information (SPI) from You on the Platform. SPI includes information relating to Your financial information, such as information regarding the payment instrument/modes used by You to make such payments, which may include cardholder name, credit/debit card number (in encrypted form) with expiration date, banking details, wallet details, etc. This information is collected at the time of making a payment to facilitate Your payment process.
        
Except for any financial information that You choose to provide while making payment for any Services on the Platform, We do not collect any other SPI in the course of providing the Services. Any SPI collected by Us shall not be disclosed to any third-party service provider without Your express consent, except as otherwise set out in this Privacy Policy or as provided in a separate written agreement between Us and You, or as required by law. This condition does not apply to publicly available information, including SPI, relating to You on the Platform.
        
We collect and use personal information provided by You (that is, information that may be used to identify You and that is not otherwise publicly available) to operate, provide, develop, and improve our Services, keep you informed of Our offerings, and the products and/or services of Our affiliates and group entities, and to enable Our affiliates and group entities to reach out to You regarding the products and services they offer.
        
In the course of providing the Services, User(s) may invite other existing User(s) or other users (“Invited Users”) to participate in any of the Services through any social media platform, including but not limited to Facebook, WhatsApp, Instagram, etc. We may use this information to contact the Invited User and invite them to register with Our Platform (if they are not already a User) and participate in the Contest they were invited to by You. The participation of the Invited User in any Contest is subject to the terms of this Privacy Policy and the Terms for the use of the Platform. You represent that the Invited Users have consented and agreed to such disclosure of their information to Us, Our affiliates, and/or Our group entities. You may also choose to invite Your friends by syncing your phonebook and inviting them directly from the Platform to utilize Our Services and/or to participate in Contests.
        
All required information is specific and based on the kind of Services and/or Contests the User wishes to participate in or access and will be utilized to provide the same.`
    },
    {
        title: "Disclosure/Sharing:",
        content: `We may share information provided by You and data concerning Your usage of the Services and participation in the Contests with Our affiliates and group entities to enable them to reach out to you regarding the products and/or services they offer. We may also share such information with third-party service providers engaged by Us for data analytics, storage, service improvements (including product enhancements), and to better serve you. Any affiliate, group entity, or third-party service provider with whom the information is shared will be required, to the best of Our efforts, to comply with the same standards applicable to Us in maintaining the security of the information.
    
By using the Platform, You expressly agree and consent to the collection, use, and storage of Your information by Us. We reserve the right to share, disclose, and transfer information collected hereunder with Our affiliates and group entities. In the event We sell or transfer all or a portion of Our business assets, consumer information may be one of the business assets shared, disclosed, or transferred as part of the transaction. You expressly grant consent and permission to Us for the disclosure and transfer of personal information for this purpose.
    
We may also share information and data concerning the usage of the Services and participation in the Contests with Our commercial partners for facilitating user engagement, marketing, promotional purposes, and other related purposes.
    
Furthermore, We reserve the right to disclose or share information with affiliates, group entities, and third parties in limited circumstances, including for complying with applicable law, responding to duly authorized legal processes, governmental requests, preventing fraud or imminent harm, and ensuring the security of Our network and services.`
    },
    {
        title: "Use of Cookies:",
        content: `To improve the effectiveness and usability of the Platform for our Users, We use “cookies”, or such similar electronic tools, to collect information to assign each visitor a unique random number as a User Identification (User ID) to understand the User's individual interests using the identified device. Unless the User voluntarily identifies himself/herself (e.g., through registration), We have no way of knowing who the User is, even if we assign a cookie to the User’s device. The only information a cookie can contain is information supplied by the User. A cookie cannot read data off the User’s device hard drive. Our advertisers may also assign their own cookies to the User's device (if the User clicks on their ad banners), a process that We do not control.
    
Our web servers automatically collect limited information about User’s device connection to the Internet, including User’s IP address, when the User(s) uses the Platform (User's IP address is a number that lets devices attached to the Internet know where to send data to the User -- such as the web pages viewed by the User). The User's IP address does not identify the User personally. We use this information to deliver our web pages to User(s) upon request, to tailor our Platform to the interests of Our User(s), to measure traffic within the Platform, and to let advertisers know the geographic locations from where Our visitors come.
    
By using the Platform, You consent to the use of cookies and the collection of Your IP address as described above. You may adjust Your browser settings to refuse cookies, but this may limit Your ability to access certain features of the Platform.`
    },
    {
        title: "Links:",
        content: `We also include links to other websites on Our Platform. Such websites are governed by their respective privacy policies, which are beyond Our control. Once the User(s) leaves Our servers (the User can tell where he/she is by checking the URL in the location bar on the User's browser), the use of any information provided by the User is governed by the privacy policy of the operator of the site which the User is visiting. That policy may differ from Our Privacy Policy. If the User can't find the privacy policy of any of these sites via a link from the site's homepage, the User may contact the site directly for more information. We are not responsible for the privacy practices or the content of such third-party websites.
    
By providing links to other websites, We do not endorse the content, products, or services offered through those websites, nor do We assume any responsibility or liability for the actions, privacy practices, or content of such websites.
    
It is recommended that Users review the privacy policies of any third-party websites they visit through links provided on Our Platform to understand their practices regarding the collection and use of personal information.`
    },
    {
        title: "Security Procedures:",
        content: "All information gathered on Our Platform is securely stored within a controlled database. The database is stored on servers secured behind a firewall; access to such servers being password-protected and strictly limited based on need-to-know basis. However, we understand that as effective as Our security measures are, no security system is impenetrable. Thus, We cannot guarantee the security of Our database, nor can We guarantee that information You supply will not be intercepted while being transmitted to Us over the internet. Further, any information You include in a posting to the discussion areas will be available to anyone with internet access. By using the Platform, you understand and agree that Your information may be used in or transferred to countries other than India"
    },
    {
        title: "Advertising:",
        content: "When We present information to Our online advertisers - to help them understand Our audience and confirm the value of advertising on the Platform - it is usually in the form of aggregated anonymized data (information that cannot be used to trace a user) of User(s) that may include statistics and/or data concerning User traffic, User responsiveness and User behaviour to such advertisements on our Platform. When you register with Us, We contact you from time to time about updating Your content to provide features which we believe may benefit You."
    },
    {
        title: "Disclaimer:",
        content: `Several deceptive emails, websites, blogs etc. claiming to be from or associated with Our Platform may or are circulating on the Internet (“Deceptive Communication”). Such Deceptive Communication may include our logo, photos, links, content or other information. Some Deceptive Communication may call the User(s) requesting the User(s) to provide login name, password etc. or inform the User(s) that the User(s) has won a prize/ gift or provide a method to commit an illegal/ unauthorized act or deed or request detailed personal information or a payment of some kind. The sources and contents of these Deceptive Communications and accompanying materials are in no way associated with Us. For Your own protection, We strongly recommend not responding to such Deceptive Communication without verifying the genuineness. You agree that We shall not be liable for any loss, damage and harm that You may suffer by relying and/or acting upon such Deceptive Communications

Except, as provided for the purpose of verification, as set out under the Terms, We and/or Our employees will not contact You to divulge your SPI`
    },
    {
        title: "Conditions of Use:",
        content: "WE DO NOT WARRANT THAT THIS PLATFORM, OUR SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS FREE. WE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PLATFORM, INCLUDING, BUT NOT LIMITED TO COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT IN EXCESS OF AN AMOUNT OF INR 100."
    },
    {
        title: "Retention of Data:",
        content: "Your personal information may be retained and may continue to be used until: (i) the relevant purposes for the use of Your personal information described in this Privacy Policy are no longer applicable; and (ii) We are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain Your personal information and the retention of Your personal information is not required for the establishment, exercise or defense of any legal claim."
    },
    {
        title: "User Account and Data Deletion:",
        content: `User(s) are entitled to request Us to delete their accounts and their personal information by sending an email with their written request to info@funzone360.club

We will do our best to respond promptly and in any event within one month of the following:

   •	- Our receipt of Your written request; or
   •	- Our receipt of any further information We may ask you to provide, to enable Us to comply with Your request, whichever is later.

As an alternative to account deletion, by writing to info@funzone360.club, You also have the option to request either:
 
  i.	the suspension of Your account, after which You will not be able to play paid contests on your User account but will continue to have access to other parts of the Platform; or
  ii.	temporary deactivation of Your account, where you will no longer be able to log into the Platform but which allows You to request reactivation of your account with all Your account data

If You proceed with permanent deactivation of your account, you will lose access to the Platform and the Services, including any User data and personal information associated with Your account. User(s) may request for account restoration within six (6) months from the date of notification of account deletion by writing to Us on info@funzone360.club

When You request deletion of Your data, We follow a deletion process that ensures that Your data is safely and completely removed from Our servers or retained only in anonymised form. We try to ensure that Our services protect information from accidental or malicious deletion. Because of this, there may be delays between when You request deletion and when copies are deleted from our active and backup systems.`
    },
    {
        title: "Applicable Law and Jurisdiction:",
        content: "By visiting this Platform, you agree that the laws of the Republic of India without regard to its conflict of laws principles, govern this Privacy Policy and any dispute arising in respect hereof shall be subject to and governed by the dispute resolution process set out in the Terms"
    },
    {
        title: "Updating Information:",
        content: `You will promptly notify Us if there are any changes, updates or modifications to Your personal information. Further, You may also review, update or modify Your personal information and User preferences by logging into Your profile page on the Platform.`
    },
    {
        title: "Contact Us:",
        content: `Any questions or clarifications with respect to this Privacy Policy or any complaints, comments, concerns or feedback can be sent to Us at: info@funzone360.club or by normal/physical mail addressed to:
Attn: Funzone360.com Team
`
    },





]

export const returnRefund = [
    {
      content: `1. We will refund any amount left in your Unutilised Account if your Account gets suspended or removed
      a. Due to Our failure to provide Services,
      b. Any instruction received from any government or regulatory authority.`,
    },
    {
      content:
        "2. We will refund the Pre-Designated Amount paid by You in case any Contest is abandoned in accordance with these Terms.",
    },
    {
      content: `3. We shall deactivate Your Account pursuant to any direction issued by an appropriate government agency and/or competent authority. If We receive requisite instructions from such authority, We shall refund the deposited amount from Your Unutilised Account to the source account, subject to applicable processing fees.`,
    },
    {
      content: `4. In the event Our Services are not available due to reasons outside Our control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Our control, such as acts of god, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemic, epidemic, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of services available on Our Platform (each a “Force Majeure Event”), then We reserve the right to cancel any Contest and process refund of the Pre-Designated Amount.`,
    },
    {
      content:
        "5. In the event of a Public Contest involving only two Users, where both the Users have entered the same teams (including the same captain and vice-captain), the Prize Money Pool and the Platform Fee shall be equally divided between the Users. Your portion of the Prize Money Pool shall be credited into Your Winning Account and Your portion of the Platform Fee shall be credited in the Discount Bonus Account.",
    },
    {
      content:
        "6. If there is any request for withdrawal of amount deposited in Unutilised Account, such refund request shall be processed net of GST i.e. applicable 28% GST paid on the amount deposited in Unutilised Account shall not be eligible for refund as per applicable government laws.",
    },
    {
      content:
        "7. Refunds will be credited within 7 working days from the date the refund request is initiated, subject to verification of eligibility by our team.",
    },
  ];

export const gameRule = [
    {
        title: "1. CONTEST RULES",
        content: `
          You agree and acknowledge that:
          1. To participate in a Contest(s), You are required to create a fantasy team (“Team”), by selecting real-life players who are participating in the specific Sports.
          2. You must finalise Your Team for the relevant Contest before the start time and/or any other adjusted time (as specified below) of the Sports (“Contest Deadline”). We may notify any extensions to the Contest Deadline in advance.
          3. Each Contest will require a minimum number of Users to participate in the Contest for it to become operational (“Pre-specified Number of Users”)
          4. In the case of a multiple-entry contest, a User may create multiple Teams to participate in such a Contest (“Multiple Entry Contest”). We reserve Our right in Our sole discretion to restrict the maximum number of Teams You may submit for each Contest format.
          5. You may participate in the Paid Contests by paying a pre-designated amount as specified on the relevant Contest page (“Pre-Designated Amount”).
          6. For each Contest, We will create a prize pool consisting of contributions from all participating Users (“Prize Money Pool”).
          7. For clarity, we have no right or interest in this Prize Money Pool.
          8. We reserve a right to deduct a fee from the Pre-Designated Amount in lieu of the Service being provided by Us (“Platform Fees”) and the balance amount shall constitute Your contribution towards the Prize Money Pool.
          9. From time to time, we may conduct/organize promotions/offers on the Platform. Your participation in such promotions/ offers shall be in accordance with the terms and conditions applicable to the Promotion.
          10. You can participate in up to 2000 Contests per match in cricket and up to 500 Contests per match in any other sports.
          11. You can only create a maximum of 20 (twenty) Teams for any Contest You are participating in.
          12. Our starting line-up feature in relation to the Contests is only to guide and assist the User in selecting their Team(s). In indicating a player’s inclusion in a starting line-up, We rely on information/data received from third-party data feed service providers (“Data Feed Providers”) and/or publicly available information.
          13. Prior to creating your Team, We strongly recommend You conduct Your own independent research of player statistics, pitch conditions, weather conditions, player performances, track record of competing teams, winning record of captains and vice captains.
          14. Flexible and Non-Flexible Contest
            (a) A Contest will be operational even if a minimum of (2)two Users join such a Contest (“Flexible Contest”)
            (b) A Contest will be operational only if the number of Users participating in such Contest is equal to the limit set by us in case of a Public Contest and by You in case of a Private Contest (“Non-Flexible Contest”)
            (c) The Prize Money Pool shall be directly proportional to the number of Users participating in a Flexible Contest. For clarity, the Prize Money Pool specified on the Contest page will decrease if the number of Users joining the Contest is less than the limit set by You for a Private Contest and by Us for a Public Contest.
          15. In participating in Contests, You agree to strictly comply with the Terms, including additional rules published by Us, such as:
            (a) “Fantasy Rules” 
            (b) any other rules and regulations (including without limitation in relation to payments made to participate in any Contest)`
    },
    {
        title: "2. CONTEST FORMATS",
        content: `
            1. Public Contest
            A Public Contest is a Contest where You can participate with other Users without any restrictions.
            Features of Public Contests:
                a. Public Contest can either be a Flexible Contest or a Non-Flexible Contest.
                b. We reserve the right to cancel the Public Contest if the number of participating Users is less than the Pre Specified Number of Users. If cancelled We will return Your Pre Designated Amount without any deduction.
                c. If a Contest is labelled as a "Guaranteed Contest" it will become operational when the Pre-Specified Number of Users join the Contest. The Prize Money Pool for each Guaranteed Contest is predetermined. We will cover the shortfall if there are not enough Users in a Guaranteed Contest to meet the guaranteed Prize Money Pool.
                d. We will declare the winners as per the Contest page, regardless of the number of Users joining the Contest
            2. Private Contest
            A Private Contest is where You can create a Contest and invite other Users (“Invited Users”) to join your Contest.
            Features of Private Contest:
                a. To create a Private Contest, You need to ensure
                    i. You provide a name to the Private Contest;
                    ii. You set a limit on the number of Users joining the Private Contest, which can range from 2-20,000 users;
                    iii. You will have the right to select whether Your Contest will be a Flexible Contest or a Non - Flexible Contest.
                    iv. Pay the Pre-Designated Amount to join the Private Contest.
                b. After You create a Private Contest, We will provide a unique identification code for the Private Contest that You can share with other Users for participating in the Private Contest (“Contest Code”).
                c. You will not be able to make any changes to the terms of the Private Contest once created
                d. Invited User(s) must enter the Contest Code and pay the Pre-Designated Amount to join the Private Contest.
                e. We will refund the Pre-Designated Amount if one hour before the Contest Deadline, the users participating in the Private Contest are less than
                    i. either the Pre-Specified Number of Users as applicable for a Flexible Contest; or
                    ii. the limit set by You for a Non-Flexible Contest.
                f. We will not be liable to the Invited User(s) for their inability to join the Private Contest for any reason not attributable to Us
          `
    },

];